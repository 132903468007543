@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk&display=swap');
@import url("https://fonts.googleapis.com/css?family=Open+Sans|Sacramento");

 .spotify{
  border-radius: 0px !important;
  border-bottom: 2px solid black;
  max-height: 80vh !important;

}
.spotify1 {
  margin-top: auto !important;
  margin-bottom: auto !important;
  border-radius: none !important;
  border-bottom: 2px solid black;
  /* max-height: 350px; Rimuovi questa riga */
  position: relative;
  height: 80vh; /* Aggiungi questa riga per occupare l'altezza massima disponibile */
}

.sfondoLoading {
  mask-image: radial-gradient(circle, rgb(227, 227, 227) 0%, rgba(255, 46, 46, 0) 100%);
  background-image: url("./img/museoload.jpg");
  background-size: cover !important;
  background-position: center center !important;
  background-repeat: repeat !important;
  height: 100% !important;
  width: 100% !important;
  position: fixed;
  top: 0;
  left: 0;
}



.logoload {
  margin-top: 10% !important;
  margin-bottom: auto !important;
  margin-right: auto !important;
  margin-left: auto !important;
  width: 250px !important;
  height: 270px !important;
  position: relative;
  top: 50% !important;
  z-index: 9991;
  filter: none !important; /* Explicitly disable filter effects */
}
@media screen and (max-width: 600px) {
  .logoload {
    margin-top: 50% !important;
    margin-right: auto !important;
    margin-left: auto !important;
    width: 250px !important;
    height: 250px !important;
    position: relative;
    z-index: 9991;
    filter: none !important; /* Explicitly disable filter effects */
  }
}

.nameload{
  font-size: 5.5em;
  font-family: 'Space Grotesk', sans-serif;
  filter: none !important; /* Explicitly disable filter effects */
  color: rgb(19, 16, 16);
}


/*------------------------------------------------------GENERALE----------------------------------------*/

  #mark{
  display: none !important;
  background-color: white !important;
  color: white !important;
  z-index:99999;
}

.notion-table-view{
  margin: 0px !important;
  padding: 0px !important;
  z-index: 99999;
  height: 100%;
}
.enp-watermark{
  position: relative !important;
  display: none !important;
  color: white !important;
  background-color: white !important;
  z-index: 99999999999999999999999999999999999999999;
}

body {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  }
body {
    overflow-y: scroll;
    padding-top: 85px;
    z-index: 9999;
    margin-top: -10px !important;
    margin-bottom: -10px !important;
  }

  

  .container {
    margin-right: 0px !important;
    z-index: 999;
  }
  
  container-fluid {
    margin-right: none;
  }
  
  .container-fluid {
    margin-left: auto !important;
    margin-right: none !important;
    padding: 0px !important;
    z-index: 999;
  }
  .row>* {
    width: auto !important;
    padding: 0px !important;
    margin: 0px !important;
  }
  
  .row {
    padding: 0px !important;
    margin: 0px !important;
    display: flex;
  }
  .row .flex {
    display: inline-flex;
    width: 100%;
  }
  .noOver {
    overflow-y: hidden !important;
    width: 100% !important;
  }
  img {
    width: 100%;
    max-height: 400px;
    background-size: contain !important;
    background-repeat: no-repeat;
    display: block;
    border-radius: 0px !important;
  }
/*MAPPA*/
  iframe { 
    width: 100%;
     height: 500px; 
     border: 2px solid black; 
     border-radius: 0px; 
     padding: 0px !important;
     margin: 0px !important; 
    }
    .mappa{
      border-radius: 0px !important;
      width: 100% !important;
      height: 400px;
      border-bottom:3px solid black ;
      margin-bottom: -5px !important;
      padding: 0px !important;
    }
    .mappa:hover{
      background-color: white !important;
      z-index: 999999999999999999 !important;
    }
/*FINE MAPPA*/
/*SCROLL BAR*/
    body::-webkit-scrollbar {
      width: 5px;
    }
    
    body::-webkit-scrollbar-track {
      background: white;
      /* color of the tracking area */
    }
    
    body::-webkit-scrollbar-thumb {
      background-color: black;
      /* color of the scroll thumb */
      border-radius: 0px !important;
      /* roundness of the scroll thumb */
      border: 3px solid black;
      /* creates padding around scroll thumb */
    }
/*FINE SCROLL BAR*/
    
/*--------------------------------------------------- FINE GENERALE--------------------------------------*/
/*------------------------------------------------------NAVBAR-------------------------------------------*/


.navbar {
  background-color: white;
}

.navbar-nav {
  text-align: right !important;
}

.navbar-brand {
  font-family: 'Space Grotesk', sans-serif;
  margin-left: 20px;
  font-size: 40px !important;
}

.navbar .navbar-nav .nav-link {
  color: #000000;
  font-size: 1.1em;
  padding-right: 1em;
  font-family: 'Space Grotesk', sans-serif;

}

.navbar .navbar-nav .nav-link:hover {
  background-color: black;
  color: white;
}

.navbar-expand-lg {
  border: 2px solid black;
}

.navbar-light .navbar-toggler-icon {
  background-image: url("./img/LogoVlecviRifatto2.png");
  width: 85px;
  height: 70px;
}

@media screen and (max-width: 300px) {
  .navbar-light .navbar-toggler-icon {
    background-image: url("./img/LogoVlecviRifatto2.png");
    width: 50px;
    height: 40px;
  }
}

.navbar-toggler {
  border-radius: 0px !important;
  margin-left: 5px;
  border: 0px !important;
  color: transparent !important;
}


/*---------------------------------------------------------FINE NAVBAR------------------------------------*/
/*--------------------------------------------------SCRITTA DELLO SPAN----------------------------------*/

.ml11 {
  font-weight: 700;
  font-size: 5.0em;
  color: rgba(14, 172, 126, );
  /*TESTO AZZURRO DELLO SPAN*/
  width: 500px;
}

.ml11-posizione {
  position: absolute;
  top: 10%;
  left: 5%;
  transform: translate(0%, 0%);
}


span {
  display: block;
  width: 500px;
  word-wrap: break-word;
  z-index: 999;
}


@media screen and (max-width: 800px) {
  .ml11 {
    font-size: 3.5em;
    width: 350px;
    word-wrap: break-word;

  }

  span {
    display: block;
    width: 350px;
    word-wrap: break-word;
  }
}

@media screen and (max-width: 600px) {
  .ml11 {
    font-size: 2.5em;
    width: 275px;

  }

  span {
    display: block;
    width: 275px;
    word-wrap: break-word;
  }
}

@media screen and (max-width: 305px) {
  .ml11 {
    font-size: 1.8em;
    width: 200px;
  }

  span {
    display: block;
    width: 200px;
    word-wrap: break-word;
  }
}

/*------------------------------------------FINE SCRITTA DELLO SPAN---------------------------------*/

/*---------------------------------------COLONNA DEL SEGMENTO "CHI SONO"--------------------------------*/

.vertical-rl {
  writing-mode: vertical-rl;
  rotate: -180deg;
  font-family: 'Space Grotesk', sans-serif;
  letter-spacing: 2px;
  position: sticky;
  top: 110px;
  margin: 0px !important;
  font-weight: 600;
  color: #000000;
  overflow-x: hidden !important;
  line-height: 1.3em;
  cursor: pointer;
}


.vertical-rl:hover {
  background-color: black;
  color: white;
}

@media screen and (max-width: 355px){
  .vertical-rl {
    writing-mode: vertical-rl;
    rotate: -180deg;
    font-family: 'Space Grotesk', sans-serif;
    letter-spacing: 2px;
    position: sticky;
    top: 110px !important;
    margin-top: 0px !important;
    font-weight: 600;
    color: #000000;
    overflow-x: hidden !important;
    line-height: 1.3em;
  }
}

@media screen and (max-width: 355px){
.foldImg{
  width: 100%;
  height: 200px !important;
  object-fit: cover;
  border-radius: 0px !important
}
}

.bordoColonnaExcel {
  border-right: 2px solid black;
  border-left: 2px solid black;
  cursor: pointer;
}
.bordoColonnaExcel:hover {

  background-color: black;
  color: white !important;
}


.bordoColonna {
  border-right: 2px solid black;
  border-bottom: 2px solid black;
  border-left: 2px solid black;
  cursor: pointer;
}
.bordoColonnaContattiimg {
  border-bottom: 2px solid black;
  border-left: 2px solid black;
  cursor: pointer;
}
@media screen and (max-width: 650px){
  .bordoColonnaContattiimg {
    border-right: 2px solid black;
    border-bottom: 2px solid black;
    border-left: 2px solid black;
    cursor: pointer;
  }
}

.bordoColonna:hover {
  background-color: black;
  color: white !important;
}

.bordoColonna:hover h1 {

  background-color: black;
  color: white !important;
}

.bordoColonna1 {
  border-bottom: 0px solid black;
  margin-left: 150px !important;
  border-right: 2px solid black;
}

.thumb {
  position: relative;
  display: inline-block;
  z-index: 999;
  border-right: 2px solid black;
  border-bottom: 2px solid black;
}
.thumbExcel {
  position: relative;
  display: inline-block;
  z-index: 999;
  border-top: 2px solid black;
  border-right: 2px solid black;
  border-bottom: 2px solid black;
}



.thumbMobile {
  position: relative;
  display: inline-block;
  z-index: 999;
  border-right: 2px solid black;
  border-bottom: 2px solid black;
}

@media screen and (max-width: 650px){
  .thumbMobile {
    position: relative;
    display: inline-block;
    z-index: 999;
    border-right: 2px solid black;
    border-bottom: 2px solid black;
    height: 450px;
    font-size:0.8em;
   
  }
}




.noBarra {
  padding: 0px !important;
  margin: 0px !important;
}

.col-md-2 {
  margin-left: 15px;
}

.sticky-top {
  top: 100px !important;
}


/*-------------------------------------FINE COLONNA DEL SEGMENTO "CHI SONO"--------------------------------*/

/*-----------------------------------------3. COLONNA A DESTRA + PROGETTI----------------------------------*/
.marginiCard {
  margin: 5% !important;
  border: 2px solid black !important;
  border-radius: 0px !important;
  font-family: 'Space Grotesk', sans-serif;
  text-align: left !important;
}

.ProgettiColonna {
  width: 18rem;
  border-right: 2px solid black;
  border-left: 2px solid black;
  overflow-y: auto;
  width: 100%;
  justify-content: start;
  align-items: flex-start;
}
.ProgettiColonna1 {
  width: 18rem;
  border-right: 2px solid black;
  border-left: 2px solid black;
  border-bottom: 2px solid black;
  overflow-y: auto;
  width: 100%;
  justify-content: start;
  align-items: flex-start;
}
@media screen and (max-width: 500px) {
  .ProgettiColonna {
    width: 9rem;
    border-right: 2px solid black;
    width: auto;
    display: block;
    align-items: center;

  }
}
.thumbSpotify {
  position: relative;
  display: inline-block;
  z-index: 999;
  border-right: 2px solid black;
  border-left: 2px solid black;
  border-bottom: 2px solid black;
}
.thumbSpotify:hover   {
  position: relative;
  display: inline-block;
  z-index: 999;
  border-right: 2px solid black;
  color: white !important;
  background-color: black;
}
.thumbSpotify:hover h1{
  color: white !important;
  background-color: black;
}

.thumb2 {
  position: relative;
  display: inline-block;
  z-index: 999;
  border-right: 2px solid black;
  border-bottom: 2px solid black;
}
.thumb2:hover   {
  position: relative;
  display: inline-block;
  z-index: 999;
  border-right: 2px solid black;
  color: white !important;
  background-color: black;
}

.thumb2:hover h1{
  color: white !important;
  background-color: black;
}

.thumb1 {
  position: relative;
  display: inline-block;
  z-index: 999;
  border-right: 2px solid black;
}

.thumb1:hover   {
  position: relative;
  display: inline-block;
  z-index: 999;
  border-right: 2px solid black;
  color: white !important;
  background-color: black;
}

.thumb1:hover h1{
  color: white !important;
  background-color: black;
}

.thumbContatti {
  position: relative;
  display: inline-block;
  z-index: 999;
  border-right: 2px solid black;
  border-bottom: 2px solid black;
}
.thumbContatti:hover {
 background-color: black;
 color: white !important;
}

.thumbContatti:hover h1 {
  background-color: black;
  color: white !important;
 }





.colRight {
  margin-right: 15px;
}

.posizioneTesto {
  position: flex;
}

.buttonRadius {
  border-radius: 0px !important;
  z-index: 999;
  color: white !important;
  font-family: 'Space Grotesk';
  font-size: 1.5em;
}

.imgBordi1 {
  border-bottom: 2px solid black !important;
  height: 950px !important;
  background-position: left center !important;
  background-repeat: repeat !important;
  background-size: contain !important;
  object-fit: fill !important;
}

.vertical-rl1 {
  writing-mode: vertical-rl;
  position: sticky !important;
  top: 110px;
  font-family: 'Space Grotesk', sans-serif;
  letter-spacing: 2px;
  margin: 0px !important;
  font-weight: 600;
  color: #000000;
  overflow-x: hidden !important;
  line-height: 1.3em;
  cursor: pointer;
  
}
.vertical-rl1:hover {
  background-color: black;
  color: white;
}

/*----------------------------------------------FINE COLONNA A DESTRA-----------------------------------*/
/*------------------------------------------------------BLOG--------------------------------------------*/
.list-group {
  border: 2px solid black !important;
}
.list-group {
  border: 1px solid black;
  border-radius: 0px !important;
  text-align: center;
  display: inline-block;
  margin-bottom: 5% !important;
  margin-top: 5%;
  margin-left: 5%;
  margin-right: 5%;
}
.list-group-item:hover {
  background-color: black !important;
  color: white !important;
}

.list-group-item-action:hover {
  background-color: black !important;
  color: white !important;
}
.mb-1 {
  font-family: 'Space Grotesk', sans-serif;
  font-weight: bold;
  color: black !important;
  text-align: center !important;
    margin-left: auto;
    margin-right: auto;
}



 .text-muted1 {
  font-family: 'Space Grotesk', sans-serif;
  color: black !important;
  z-index: 99999;
}
.text-muted2{
  font-family: 'Space Grotesk', sans-serif;
  color: black !important;
  z-index: 99999;
  margin-left: 50%;
}
.text-muted1:hover {
  font-family: 'Space Grotesk', sans-serif;
  color: white !important;
  z-index: 99999;
}
.hoverBlog{
  background-color: white;
  color: black;
}
.hoverBlog:hover{
  background-color: black;
  color: white !important;
}


.hoverBlog:hover small{
  background-color: black;
  color: white !important;
}
.hoverBlog:hover a{
  background-color: black;
  color: white !important;
}
.hoverBlog:hover h5{
  background-color: black;
  color: white !important;
}

.bordiContainerBlog {
  border-top: 2px solid black;
}

.d-flex1 {
  display: flex !important;
  z-index: 9999;
}

@media screen and (max-width: 1200px) {
  .d-flex1 {
    display: contents !important;
    z-index: 9999;
  }
}

/*------------------------------------------------FINE BLOG---------------------------------------------*/

/*---------------------------------------------SEZIONE CONTATTI----------------------------------------*/
.ProgettiColonnaContatti {
  width: 18rem;
  border-right: 1px solid black;
  border-left: 1px solid black;
  border-bottom: 2px solid black;
  overflow-y: auto;
  width: 100%;
  justify-content: start;
  align-items: flex-start;
}

div.gallery img {
  width: 100%;
  height: 250px;
  margin: 0 0 0 0;
  padding: 0 0;
  border-right: 1.5px solid black;
  border-left: 1.5px solid black;
  background-size: cover !important;
  background-repeat: no-repeat;
  display: block;
  border-radius: 0px !important;
  background-color: white;
}


.responsive {
  padding: 0 0px;
  float: left;
  width: 33.3333%;
  float: left;
  margin: 0 0px 0 0px !important;

}



@media only screen and (max-width: 900px) {
  .content-title {
    font-size: small;
  }
}



.clearfix:after {
  content: "";
  display: table;
  clear: both;
}


/* SEZIONE HOVER QUANDO SI PASSA SOPRA IL MOUSE*/

.content {
  position: relative;
  width: 100%;
  margin: auto;
}

.content-title {
  font-family: 'Space Grotesk', sans-serif;
  color: white;

}


.content-details {
  text-decoration: none;
  position: absolute;
  text-align: center;
  padding-left: 1em;
  padding-right: 1em;
  width: 100%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.9s ease-in-out 0s;
  -moz-transition: all 0.9s ease-in-out 0s;
  transition: all 0.9s ease-in-out 0s;
}

.content:hover .content-details {
  top: 50%;
  left: 50%;
  opacity: 5;
}

.content-details h3 {
  border: 2px solid black;
  color: black;
  font-weight: 400;
  letter-spacing: 0.10em;
  margin-bottom: 0.5em;
  text-transform: uppercase;
}

@media only screen and (max-width: 700px) {
  .content-details h3 {
    font-weight: 300;
    letter-spacing: 0.08em;
  }
}


a {
  text-decoration: none !important;
}



.buttone {
  top: 0;
  left: 0;
  padding: 10%;
  transition: all .15s linear 0s;
  position: relative !important;
  display: inline-block !important;
  background-color: white !important;
  box-shadow: -6px 6px 0 black;
  text-decoration: none;
  border-radius: 0px !important;

  &:hover {
    top: 3px;
    left: -3px;
    box-shadow: -3px 3px 0 black;
    border: 2px solid black !important;
  }

  &::after {
    top: 1px;
    left: -2px;
    width: 4px;
    height: 4px;
  }

  &::before {
    bottom: -2px;
    right: 1px;
    width: 4px;
    height: 4px;
  }
}

&::after {
  transition: all .15s linear 0s;
  content: '';
  position: absolute !important;
  top: 2px;
  left: -4px;
  width: 8px;
  height: 8px;
  background-color: white;
  transform: rotate(45deg);
  z-index: -1;

}

&::before {
  transition: all .15s linear 0s;
  content: '';
  position: absolute;
  bottom: -4px;
  right: 2px;
  width: 8px;
  height: 8px;
  background-color: white;
  transform: rotate(45deg);
  z-index: -1;
}



h3.buttone {
  position: relative;
}

h3:active.buttone {
  top: 6px;
  left: -6px;
  box-shadow: none;

  &:before {
    bottom: 1px;
    right: 1px;
  }

  &:after {
    top: 1px;
    left: 1px;
  }
}

@media only screen and (max-width: 700px) {
.buttone{
  font-size: 9px;
}
}

/*------------------------------------------- FINE SEZIONE CONTATTI--------------------------------------*/


/*------------------------------------------1.1 CSS DELLA PAGINA DELLA BIO-------------------------------*/
.testoBio {
  font-size: 1.2em;
  text-align: center;
  position: absolute;
  z-index: 99999;
  top: 8%;
  left: 10%;
  columns: black;
  font-family: 'Space Grotesk', sans-serif;
  font-weight: 800;
  height: 350px !important; /* Imposta l'altezza desiderata con overflow auto */
  overflow: auto;
  margin: 0px 25px 0px 25px !important;
}
.testoweblist {
  font-size: 1.2em;
  text-align: center;
  position: relative;
  columns: black;
  font-family: 'Space Grotesk', sans-serif;
  font-weight: 800;
  margin: 15px 50px 0px 50px !important;
}
.testoprogetti {
  border-bottom: 2px solid black;
  font-size: 1.2em;
  text-align: center;
  position: relative;
  columns: black;
  font-family: 'Space Grotesk', sans-serif;
  font-weight: 800;
  margin-top: 34px!important;
}
.testoBlog {
  font-size: 1.2em;
  text-align: center;
  z-index: 99999;
  margin-top: 25px !important;
  columns: black;
  font-family: 'Space Grotesk', sans-serif;
  font-weight: 100;
}

.testoBio::-webkit-scrollbar {
  width: 5px;
}

.testoBio::-webkit-scrollbar-track {
  background: white;
  /* color of the tracking area */
}

.testoBio::-webkit-scrollbar-thumb {
  background-color: white;
  /* color of the scroll thumb */
  border-radius: 0px !important;
  /* roundness of the scroll thumb */
  border: 1px solid white;
  /* creates padding around scroll thumb */
}


.marginiCard1 {
  margin: 0px 15px 0px 15px !important;
  border-radius: 0px !important;
  font-family: 'Space Grotesk', sans-serif;
  text-align: left !important;
  border-left:2px solid black !important ;
  border-bottom: 2px solid black !important;
}

.containerCardBio {
  margin-left: -30px !important;
  overflow-x: hidden !important;
  overflow-y: hidden !important;
}

.imgBordi {
  border-bottom: 2px solid black !important;
  height: 200px !important;
  -webkit-filter: grayscale(80%); /* Safari 6.0 - 9.0 */
  filter: grayscale(80%);
}
.noClassfora{
  text-decoration: none !important;
  color: white !important;
}
/*---------------------------------------1.1FINE CSS DELLA PAGINA DELLA BIO-------------------------------*/

/*---------------------------------------1.1 CSS DELLA PAGINA DEI CONTATTI-------------------------------*/
.bordoColonnaContatti {
  flex: 0 0 auto;
  width: 10% !important;
  height: 100% !important;
  margin: 0px !important;
  padding: 0px !important;
  z-index: 9999;
  border-right: 2px solid black;
  border-left: 2px solid black;
  cursor: pointer;
}
.bordoColonnaContatti:hover {
 background-color: black;
 color: white !important;
}
.bordoColonnaContatti:hover h1 {
  background-color: black;
  color: white !important;
 }

@media screen and (max-width: 813px) {
  .bordoColonnaContatti {
    width: 12% !important;
  }
}

@media screen and (max-width: 724px) {
  .bordoColonnaContatti {
    width: 14% !important;
  }
}

@media (max-width: 660px) {
  .resizeImg {
    height: 100% !important;
    max-height: none !important;
  }

}
/*---------------------------------------1.1 CSSFINE DELLA PAGINA DEI CONTATTI-------------------------------*/








/*SCRITTA SCHEDA BIO*/
.titolo h1 {
  width: 100%;
  display: inline-block;
  position: relative;
  color: black;
  text-align: center;
  font-family: 'Space Grotesk', sans-serif;
  font-size: 3.5em !important;
  font-weight: 600 !important;
  text-decoration: none;
  margin-top: 0px !important;
  border-right: 2px solid black;
  border-left: 2px solid black;
  border-bottom: 2px solid black;

}

/*FINE SCRITTA BIO*/


/* Style the buttons */
#myBtnContainerChi{
  text-align: center;
  float: row;
  border: 2px solid black;
  background-image: url("./img/miami.jpg");
  background-repeat: no-repeat;
  background-size:cover;
  background-position: center;
}
#myBtnContainer{
  text-align: center;
  float: row;
  border: 2px solid black;
  background-image: url("./img/list.jpg");
  background-repeat: no-repeat;
  background-size:cover;
  background-position: center;
}
#myBtnContainer1{
  text-align: center;
  float: row;
  border: 2px solid black;
  background-image: url("./img/laptop.jpg");
  background-repeat: no-repeat;
  background-size:cover;
  background-position: center;
}
.button-1{
  text-align: center;
  float: row;
  border: 2px solid black;
  background-image: url("./img/miami.jpg") !important;
  background-repeat: no-repeat;
  background-size:cover;
  background-position: center;
}
.button-1Blog{
  text-align: center;
  float: row;
  border: 2px solid black;
  background-image: url("./img/laptop.jpg") !important;
  background-repeat: no-repeat;
  background-size:cover;
  background-position: center;
}
.button-2{
  text-align: center;
  float: row;
  border: 2px solid black;
  background-image: url("./img/museum3.jpg") !important;
  background-repeat: no-repeat;
  background-size:cover;
}
.button-2Blog{
  text-align: center;
  float: row;
  border: 2px solid black;
  background-image: url("./img/finance.jpg") !important;
  background-position: top top !important;
  background-repeat: no-repeat;
  background-size:cover;
}
.button-3{
  text-align: center;
  float: row;
  border: 2px solid black;
  background-image: url("./img/list.jpg") !important;
  background-repeat: no-repeat;
  background-size:cover;
  background-position: center !important;
}
.button-3Blog{
  text-align: center;
  float: row;
  border: 2px solid black;
  background-image: url("./img/growth.jpg") !important;
  background-repeat: no-repeat;
  background-size:cover;
  background-position: center !important;
}
.button-4Blog{
  text-align: center;
  float: row;
  border: 2px solid black;
  background-image: url("./img/list1.jpg") !important;
  background-repeat: no-repeat;
  background-size:cover;
  background-position: center !important;
}



.btnBlog {
  font-family: 'Space Grotesk', cursive;
  border: none;
  outline: none;
  padding: 12px 12px !important;
  background-color: white !important;
  color: black;
  cursor: pointer;
  border: 2px solid black !important;
  border-radius: 0px !important ;
  margin-top: 12% !important;
  margin-bottom: 10% !important;
}
/* Add a light grey background on mouse-over */
@media (max-width: 980px) {
 .btnBlog{
  margin-bottom: 20% !important;

 }

}
@media (max-width: 600px) {
  .btnBlog{
   margin-bottom: 30% !important;
   white-space: nowrap;
   width: auto !important;
   font-size: 0.7em !important;
 
  }
 
 }
 @media (max-width: 350px) {
  .btnBlog{
   margin-bottom: 30% !important;
   white-space: nowrap;
   width: auto !important;
   font-size: 0.5em !important;
 
  }
 
 }

.btnBlog:hover {
  background-color: black !important;
  color: white !important;
}


.btnBlog {
  width: 19.99999%;
  position: relative;
  display: inline-block flex;
  outline: none;
  border: 0;
  vertical-align: middle;
  text-decoration: none !important;
  font-size: 0.7rem;
  font-weight: 700;
  text-transform: uppercase;
}

@media all and (max-width:1063px) {

  .btnBlog {
      width: 19.99999%;
      display: inline-block;
      font-size: 0.4rem;
      align-items: center;

 }
  .btnBlog:hover {
      background-color: white;
      color: black;
 }

}



      


.mainsss-container {
  font-size: 14.5px;
  border: 2px solid black !important;
}
.mainsss-container {
  font-family: 'Space Grotesk', cursive;
  line-height: 1.45em;
  color: #404646;
}

.mainsss-container {
  margin-top: 0.5rem;
}

.mainssss-container {
  font-size: 14.5px;
}
.mainssss-container {
  font-family: 'Space Grotesk', cursive;
  line-height: 1.45em;
  color: #404646;
}

.mainssss-container {
  margin: 1.6rem 0.8rem;
}

    
      
.gridsss-container {
  margin: auto;
  display: flex;
  grid-gap: 1.1312rem;
  grid-template-columns: repeat(auto-fit, 10.8rem);
  grid-auto-rows: 20.8rem;
  grid-auto-flow: dense;
  justify-content: center;
  width: 100% !important;
}
      
 .gridsss1-container {
  margin: auto;
  display: flex;
  grid-gap: 1.1312rem;
  grid-template-columns: repeat(auto-fit, 15.8rem);
  grid-auto-rows: 24.8rem;
  grid-auto-flow: dense;
  justify-content: center;
  width: 100% !important;
}
      
.cardsss {
  grid-row: auto / span 1;
  grid-column: auto / span 1;
  background-color: white;
  box-shadow: 1px 3px 3px rgba(0, 10, 20, 0.06);
}

.cardsss__image {
  height: 100%;
  max-height: 100%;
  width: 100%;
  display: flex;
  border-radius: 0% !important;
}
.cardsss__image img {
  height: 100%;
  min-height: 100%;
  max-height: 550px;
  width: 100%;
  object-fit: cover;
  border-radius: 0% !important;
}
.cardsss__side-by-side {
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
}
.cardsss__side-by-side--m {
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
}
.cardsss__side-by-side--m img {
  min-height: auto;
}
.cardsss__content {
  padding: 3.6rem !important;
  border-left: 2px solid black;
}
.cardsss__button {
  margin: 1.6rem 0;
  text-align: center;
  padding: 0.8rem 1.6rem;
  background: none;
  border: 0.5px solid #777;
  border-radius: 2px;
}
.cardsss__button:hover {
  border-color: #d099a0;
}
.cardsss--featured {
  grid-row: auto / span 3;
  grid-column: auto / span 2;
}
.cardsss--2x {
  grid-row: auto / span 2;
  grid-column: auto / span 2;
}


.paddingsss-large--l {
  padding: 1.6rem;
}

@media (max-width: 1025px) {
  .gridsss-container {
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: auto;
  }
  .cardsss {
    min-height: 12.8rem;
  }
}

@media (min-width: 1025px) {
  .gridsss-container {
    grid-gap: 1.6rem;
  }
  .cardsss__side-by-side--m {
    flex-flow: row nowrap;
  }
  .cardsss__side-by-side--m img {
    min-height: 100%;
  }
  .cardsss--featured {
    grid-row: auto / span 2;
    grid-column: 1 / -1;
  }
}

@media (min-width: 836px) {
  .paddingsss-large--l {
    padding: 3.2rem;
  }
}


.timeline {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.timeline-item {
  width: 100%;
  border-left: 2px solid black;
  padding: 20px;
  margin: 10px 0;
}

.timeline-content {
  margin-left: 10px;
}
.timeline-container {
  width: 100%;
  height: 200px; /* Imposta l'altezza desiderata con overflow auto */
  overflow: auto;
}
.timeline-container1 {
  width: 100%;
  height: 333px; /* Imposta l'altezza desiderata con overflow auto */
  overflow: auto;
}


.timeline-container::-webkit-scrollbar {
  width: 5px;
}

.timeline-container::-webkit-scrollbar-track {
  background: white;
  /* color of the tracking area */
}

.timeline-container::-webkit-scrollbar-thumb {
  background-color: black;
  /* color of the scroll thumb */
  border-radius: 0px !important;
  /* roundness of the scroll thumb */
  border: 3px solid black;
  /* creates padding around scroll thumb */
}
.timeline-container1::-webkit-scrollbar {
  width: 5px;
}

.timeline-container1::-webkit-scrollbar-track {
  background: white;
  /* color of the tracking area */
}

.timeline-container1::-webkit-scrollbar-thumb {
  background-color: black;
  /* color of the scroll thumb */
  border-radius: 0px !important;
  /* roundness of the scroll thumb */
  border: 3px solid black;
  /* creates padding around scroll thumb */
}



.dropdown-menu.show {
  display: block;
  padding: 5px;
  border-radius: 0px !important;
}
.dropdown-item{
  margin: 1px !important;
}


span{
  width: auto;
}
.link-bar {
  display: flex;
  align-items: center; /* Opzionale: allinea verticalmente al centro */
  font-family: 'Space Grotesk', sans-serif;
  font-size: 2rem;
  letter-spacing: 2px;
  font-weight: 600;
  border-bottom: 2px solid black;
  border-left: 2px solid black;
  border-right: 2px solid black;
}
.link-bar1 {
  display: flex;
  align-items: center; /* Opzionale: allinea verticalmente al centro */
  font-family: 'Space Grotesk', sans-serif;
  font-size: 2rem;
  letter-spacing: 2px;
  font-weight: 600;
  border-top: 2px solid black;
  border-left: 2px solid black;
  border-right: 2px solid black;
  border-bottom: 2px solid black;
}

@media (max-width: 780px) {
  .link-bar {
    display: block;
    font-size: 1.5rem;
    margin-left: auto !important;
    margin-right: auto !important;

  }
  .link-bar1 {
    display: block;
    font-size: 1.5rem;
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .support-text{
    margin-left: 30% !important;
    margin-right: auto !important;
    font-size: 1rem;
  }
}

.support-container{
  display: flex !important;
  top:10%;
}
.paypal-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 2px; /* Regola il margine interno del pulsante per centrare l'icona di PayPal */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
}
@media (max-width: 780px) {
  .paypal-button {
    width: 30px;
    height: 30px;
  }
}


.support-text {
  margin-left: auto;
  margin-right: auto;
}




.maint-container {
  margin: 1.6rem 0.8rem;
}

.gridt-container {
  margin: auto;
  display: grid;
  grid-gap: 1.1312rem;
  grid-template-columns: auto auto auto auto;
  grid-auto-rows: 12.8rem;
  grid-auto-flow: dense;
  justify-content: center;
}

@media (max-width: 1032px) {
  .gridt-container {
    margin: auto;
    display: block;
    grid-gap: 1.1312rem;
    grid-template-columns: auto auto auto auto;
    grid-auto-rows: 12.8rem;
    grid-auto-flow: dense;
    justify-content: center;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
}


.cardt {
  grid-row: auto/span 1;
  grid-column: auto/span 1;
  background-color: white;
  box-shadow: 1px 3px 3px rgba(0, 10, 20, 0.06);
  border: 2px solid black;
  margin-bottom: 5px !important;

}
.cardt h1, .cardt h2, .cardt h3, .cardt h4, .cardt p {
  margin-top: 0;
  font-weight: normal;
}
.cardt__image {
  height: 100%;
  max-height: 100%;
  width: 100%;
  display: flex;
  border-radius: 0% !important;
  margin-top: auto !important;
  margin-bottom: auto !important;

}
.cardt__image img {
  height: 100%;
  min-height: 100%;
  max-height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 0% !important;
}
.cardt__side-by-side {
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
}
.cardt__side-by-side--m {
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
}
.cardt__side-by-side--m img {
  min-height: auto;
}
.cardt__content {
  padding: 1rem;
  font-size: 14px;
  font-family: 'Space Grotesk', cursive;
}
.cardt__button {
  margin: 1.6rem 0;
  text-align: center;
  padding: 0.8rem 1.6rem;
  background: none;
  border: 0.5px solid #777;
  border-radius: 0% !important;
}
.cardt__button:hover {
  border-color: #d099a0;
}
.cardt--featured {
  grid-row: auto/span 3;
  grid-column: auto/span 2;
}
.cardt--2x {
  grid-row: auto/span 2;
  grid-column: auto/span 2;
}
.cardt--full {
  grid-row: auto/span 4;
  grid-column: auto/span 4;
}
.cardt--vertical {
  grid-row: auto/span 2;
}
.cardt--horizontal {
  grid-column: auto/span 2;
}
.cardt--horizontal1 {
  grid-column: auto/span 4;
}
.cardt--frameless {
  background-color: transparent;
  box-shadow: none;
}

.paddingt-large {
  padding: 3.2rem;
}
.paddingt-large--l {
  padding: 1.6rem;
}

.bigt-script {
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  font-family: "Sacramento", sans-serif;
  font-size: 4.3em;
  line-height: 1.15em;
  text-align: center;
}
.bigt-script p {
  margin: 0;
}

@media (max-width: 413px) {
  .gridt-container {
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: auto;
  }
 
}
@media (min-width: 1025px) {
  .gridt-container {
    grid-gap: 1.6rem;
  }
  .cardt__side-by-side--m {
    flex-flow: row nowrap;
  }
  .cardt__side-by-side--m img {
    min-height: 100%;
  }
  .cardt--featured {
    grid-row: auto/span 2;
    grid-column: 1/-1;
  }
}

@media (min-width: 836px) {
  .paddingt-large--l {
    padding: 3.2rem;
  }
}



      

.grid {
columns: 18rem;
gap: 1rem;
counter-reset: grid;
}

     @media only screen and (min-width: 1230px) {
.grid {
display: block;
columns: 25rem;
gap: 1rem;
counter-reset: grid;
}
}

.item + .item {
margin-top: 1rem;
}

.item {
break-inside: avoid;
aspect-ratio: 4 / 3;
border: 2px solid black;
border-radius: 0px !important;
}

img{
width:100%;
  height:100%;
   object-fit: cover;
 border-radius: 0px !important;
}


.item:nth-child(2n) {
aspect-ratio: 1;
background: transparent;
}

.item:nth-child(2n - 1) {
aspect-ratio: 1;
background: transparent;
}

body.item-view .post-body-container img{
  width:100%;
  height:100%;
   object-fit: cover;
 border-radius: 0px !important;
  margin: 0px !important;
}

.filterDivv {
  color: #ffffff;
  text-align: center;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
}




      .pulisci{
clear: both;
margin: 50px 0 50px 0 !important;
}

.carousel .slide{
  height: 530px;
}
@media (max-width: 620px) {
  .carousel .slide{
    height: 450px;
  }
}

.carousel-status{
  top:10px !important;
}

.dropdown-menu{
overflow: auto !important;
height: 150px !important;
}

.dropdown-menu::-webkit-scrollbar {
  width: 1px;
}

.dropdown-menu::-webkit-scrollbar-track {
  background: white;
  /* color of the tracking area */
}

.dropdown-menu::-webkit-scrollbar-thumb {
  background-color: white;
  /* color of the scroll thumb */
  border-radius: 0px !important;
  /* roundness of the scroll thumb */
  
  /* creates padding around scroll thumb */
}

.colored-text{
  color: orange !important;
  white-space: nowrap !important;
}

.titoloBlog{
  border-top: 2px solid black;
  border-right: 2px solid black;
  font-size: 2em;
  background-color: rgb(186, 230, 216);
  font-weight: 800;
  text-align: center;
  font-family: 'Space Grotesk', sans-serif;
  margin: 0px !important;
}
.titolo1Blog{
  font-size: 2em;
  background-color: rgb(186, 230, 216);
  font-weight: 800;
  text-align: center;
  font-family: 'Space Grotesk', sans-serif;
  margin: 0px 0px 0px 0px !important;
  border-left: 2px solid black;
  border-right: 2px solid black;
}
.titolo2Blog{
  border-top: 2px solid black;
  border-bottom: 2px solid black;
  font-size: 2em;
  background-color: rgb(186, 230, 216);
  font-weight: 800;
  text-align: center;
  font-family: 'Space Grotesk', sans-serif;
}
.sezioneBlog{
  border-top: 2px solid black;
  border-bottom: 2px solid black;
  font-size: 1.5em;
  font-weight: 400;

}
.textUl{
  text-align: left;
}

.fotoBlog{
  height: 150px;
  border-bottom: 2px solid black;
}

.fotoBlog1 {
  margin-left: auto;  /* Imposta il margine sinistro su auto per centrare l'elemento orizzontalmente */
  margin-right: auto; /* Imposta il margine destro su auto per centrare l'elemento orizzontalmente */
  height: 100%;       /* Imposta l'altezza al 100% (assicurati che l'elemento padre abbia un'altezza definita) */
  max-width: 500px;   /* Imposta la larghezza massima a 500px per evitare che l'immagine diventi troppo grande */
  border-bottom: 2px solid black;
  background-repeat: no-repeat;
  background-size: contain !important;
  background-position: center center;
  border: 2px solid black;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  justify-content: center !important;
  align-items: center !important;
}
.fotoBlog1 img {
  width: 100%;
  height: auto;
  transition: transform 0.3s ease-in-out;
}

.fotoBlog1.Zoomed img {
  transform: scale(3.5); /* Modifica il valore per regolare l'ammagnificazione dello zoom */
}

.more{
  font-size: 8px;
 
}
.more::after{
  content: "------>";
  color: black;
  color: black;
  position: absolute;
  left: -8px;
  top:-5px;
}
.more::after:hover {
  content: "------>";
  color: white;
  position: absolute;
  left: -8px;
  top: -5px;
}

.containImg{
  background-position: center;
  background-size: contain !important;
}

.commentiExcel{
  border: 2px solid black;
}
.commentiExcel1{
  border-right: 2px solid black;
  border-left: 2px solid black;
  border-bottom: 2px solid black;
}

.hideCarouselControls .carousel-control-prev,
.hideCarouselControls .carousel-control-next {
  display: none;
}

.buttonSpacing {
  margin-right: 10px; /* Aggiunge uno spazio a destra del primo bottone */
}

@media (max-width: 620px) {
  .buttonSpacing {
    margin-top: 10px !important; /* Aggiunge uno spazio a destra del primo bottone */
    margin-bottom: 10px !important;
  }
}

footer {
  font-family: 'Space Grotesk', sans-serif;
  font-weight: bold;
  font-size: 12px; /* Testo piccolo */
  text-align: center; /* Testo centrato */
  padding: 2px 0;
}